body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.removeLine .MuiInput-underline:before {
  border-bottom: none;
}

.MuiPickersBasePicker-container .MuiToolbar-root h4.MuiPickersToolbarText-toolbarTxt {
  font-size: 22px;
}

.MuiPickersBasePicker-container .MuiToolbar-root {
  padding: 10px;
}

#bookingForm .MuiGrid-root .MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  min-width: 10%;
}

#bookingForm .MuiGrid-root .MuiPickersBasePicker-container .MuiPickersBasePicker-pickerView {
  min-width: 80%;
}

.scrollableModalContainer, .scrollableModal {
  max-height: 90vh;
  overflow: auto;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;

}
